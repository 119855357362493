<template>
  <div class="index-page">
    <div class="banner">
      <div class="login-box">
        <div class="content">
          <img src="../../../assets/loginlogo.png" class="header-logo" alt="logo">
          <div class="sys-name">安全检查智能管理平台</div>
          <el-form ref="loginForm" size="medium" :rules="rules" :model="form" class="login-form">
            <el-form-item prop="account">
              <el-input placeholder="请输入登录账号" auto-complete="off" v-model="form.account"><i slot="prefix" class="iconfont icon-dengluicon_user"></i></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input type="password" auto-complete="off" show-password placeholder="请输入登录密码" v-model="form.password" @keydown.enter.native="onSubmit"><i slot="prefix" class="iconfont icon-dengluicon_password"></i></el-input>
            </el-form-item>
            <el-form-item>
              <el-button class="login-btn" type="primary" :loading="loginLoading" @click="onSubmit">登录</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div class="bot-msg">川ICP备11017824号-4 / 川ICP证130164号 成都市公安局青羊分局备案编号:110105000501</div>
  </div>
</template>

<script>
import { loginSys } from 'api/admin/institution'
import store from '@/utils/store';
export default {
  data(){
    let validateAccount = (rule, value, callback) => {
      const codeReg = /^[A-Za-z0-9]{6,11}$/
      if (!value) {
        return callback(new Error('请输入登录账号'));
      }else if(!codeReg.test(value)){
        callback(new Error('请输入正确的登录账号'));
      }else{
        callback()
      }
    };
    let validatePwd = (rule, value, callback) => {
      const pwdReg = /^[A-Za-z0-9]{6,16}$/
      if (!value) {
        return callback(new Error('请输入登录密码'));
      }else if(!pwdReg.test(value)){
        callback(new Error('请输入正确的登录密码'));
      }else{
        callback()
      }
    };
    return{
      form:{
        account: '',
        password: ''
      },
      loginLoading: false,
      rules: {
        account: [
          { validator: validateAccount, trigger: 'blur' },
        ],
        password: [
          { validator: validatePwd, trigger: 'blur' }
        ],
      }
    }
  },
  methods: {
    onSubmit() {
      // 登录api、操作、判断
      // loginSys({account:'xuyuxiang',password:'abc123456'}).then(res => {
      //   this.$store.commit('SET_TOKEN', res.data.token);
      //   store.setSession('username',res.data.name)
      //   this.$router.push({ name: 'Institution' })
      // })
      this.$refs.loginForm.validate(valid => {
        if (!valid) return;
        this.loginLoading = true
        loginSys(this.form).then(res => {
          this.$store.commit('SET_TOKEN', res.data.token);
          store.setSession('username',res.data.name)
          this.$router.push({ name: 'Institution' })
        }).finally(() => {
          this.loginLoading = false
        })
      });
    },
  }
}
</script>

<style lang="less" scoped>
.index-page{
  height: 100vh;
  width:100%;
  background: url("../../../assets/bg.png") center top no-repeat;
}
.banner{
  height: calc(100vh - 42px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .login-box{
    width: 440px;
    height: 467px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 4px 10px 0 rgba(2,14,28,0.20);
    .content{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .header-logo{
        padding: 13px 0 8px 0;
      }
      .sys-name{
        font-size: 24px;
        color:#333;
        margin-bottom: 50px;
      }
      .login-form{
        width: 324px;
        .login-btn{
          width: 100%;
          border-radius: 20px;
          height: 40px;
          margin-top: 26px;
          font-size: 16px;
        }
        /deep/.el-input__inner{
          border-radius:21px;
          background: #F3F6FE;
          height: 40px;
          line-height: 40px;
          padding-left: 40px;
        }
        /deep/.el-input__prefix{
          padding-left: 6px;
          top:2px;
        }
      }
    }
  }
}
.iconfont{
  font-size: 24px;
}
.bot-msg{
  font-size: 12px;
  color:#666;
  text-align: center;
}
</style>
